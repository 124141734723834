
.headerWrap {
  text-align: right;
  padding: 18px 0 20px 0;
  position: relative;
  min-height: 200px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    padding: 16px 0;
  }
  .borderWrap {
        position: absolute;
        margin-top: 100px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 35px;
        background-color: #FB6317;
        border: 1px solid black;
        box-sizing: border-box;
        }
  .loginText {
        font-family: "benton-sans-wide", sans-serif;
        color: white;
        text-decoration: underline;
        font-size: 21px;
        margin-right: 30px;
  }
  .brandSec {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      max-width: 450px;
      padding-bottom: 45px;
      @media (max-width: 1199px) {
        max-width: 380px;
      }
      @media (max-width: 767px) {
        max-width: 320px;
        padding-bottom: 25px;
      }
    }
  }
  .webLink {
    font-size: 22px;
    padding: 6px 33px;
    background: #FB6317;
    color: #000;
    text-decoration: none;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    font-family: 'Poppins', sans-serif;
    @media (max-width: 1199px) {
      font-size: 18px;
      padding: 6px 28px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      padding: 4px 12px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
}
