.dorman-webLink {
  display: none;
  @media (max-width: 575px) {
    display: block;
    text-align: center;
    margin-top: 20px;
    a {
      background: #FB6317;
      color: #000;
      text-decoration: none;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 33px;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      padding: 4px 12px;
    }
  }
}
.heroSec {
  padding: 66px 0 0px 0;
  @media (max-width: 1599px) {
    padding: 60px 0 0px 0;
  }
  @media (max-width: 1199px) {
    padding: 40px 0 0px 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0 30px 0;
  }
  .heroBox {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 575px) {
      flex-flow: column;
    }
    .bgOrangeBox {
      background-color: #FB6317;
      color: #2f3338;
      padding: 50px 50px;
      display: flex;
      flex-flow: column;
      text-align: center;
      justify-content: center;
      @media (max-width: 1439px) {
        padding: 80px 40px;
      }
      @media (max-width: 1199px) {
        padding: 40px 20px;
      }
      @media (max-width: 991px) {
        padding: 28px 16px;
      }
      @media (max-width: 767px) {
        padding: 12px;
      }
      @media (max-width: 575px) {
        padding: 40px 16px;
      }
      img {
        width: auto;
        height: auto;
        max-width: 80%;
        margin-left: 30px;
        margin-bottom: 50px;
        }
      hr {
        border: 5px solid white !important;
        }
      h2 {
        font-size: 33px;
        font-weight: 700;
        font-family: "benton-sans-wide", sans-serif;
        @media (max-width: 1599px) {
          font-size: 28px;
        }
        @media (max-width: 1439px) {
          font-size: 25px;
        }
        @media (max-width: 1199px) {
          font-size: 22px;
        }
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
      h3 {
        font-size: 20px;
        font-weight: 700;
        font-family: "benton-sans-wide", sans-serif;
        margin-bottom: 30px;
        @media (max-width: 1599px) {
          font-size: 18px;
        }
        @media (max-width: 1199px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
      h1 {
        font-family: "benton-sans-wide", sans-serif;
        color: #641900;
        @media (max-width: 1599px) {
          font-size: 64px;
        }
        @media (max-width: 1439px) {
          font-size: 56px;
        }
        @media (max-width: 1199px) {
          font-size: 50px;
        }
        @media (max-width: 991px) {
          font-size: 40px;
          margin-bottom: 0;
        }
        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
      h5 {
        font-family: "benton-sans-wide", sans-serif;
        font-size: 37px;
        @media (max-width: 1599px) {
          font-size: 28px;
        }
        @media (max-width: 1199px) {
          font-size: 20px;
        }
        @media (max-width: 991px) {
          font-size: 18px;
        }
        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
      .signUp {
        background: #000;
        color: #fff;
        padding: 12px 45px;
        border: 0;
        width: fit-content;
        margin: auto;
        font-size: 26px;
        font-family: "benton-sans-wide", sans-serif;
        @media (max-width: 1599px) {
          font-size: 22px;
        }
        @media (max-width: 1199px) {
          font-size: 18px;
        }
        @media (max-width: 991px) {
          font-size: 16px;
          padding: 8px 36px;
        }
        @media (max-width: 767px) {
          font-size: 13px;
          padding: 6px 36px;
        }
      }
      p {
        font-size: 25px;
        font-weight: 300;
        margin-top: 8px;
        margin-bottom: 0;
        @media (max-width: 1599px) {
          font-size: 20px;
        }
        @media (max-width: 1199px) {
          font-size: 18px;
        }
        @media (max-width: 991px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          font-size: 13px;
        }
        button {
          border: 0;
          padding: 0;
          font-weight: 700;
          font-style: italic;
          background-color: transparent;
        }
      }
    }
    .dormonText {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      padding: 100px 50px;
      @media (max-width: 1439px) {
        padding: 80px 40px;
      }
      @media (max-width: 1199px) {
        padding: 40px 20px;
      }
      @media (max-width: 767px) {
        padding: 30px 16px;
      }
      h2 {
        color: #fff;
        font-size: 81px;
        text-align: center;
        margin-bottom: 62px;
        font-family: "benton-sans-wide", sans-serif;
        @media (max-width: 1599px) {
          font-size: 68px;
        }
        @media (max-width: 1439px) {
          font-size: 58px;
          margin-bottom: 42px;
        }
        @media (max-width: 1199px) {
          font-size: 52px;
          margin-bottom: 32px;
        }
        @media (max-width: 991px) {
          font-size: 42px;
          margin-bottom: 24px;
        }
        @media (max-width: 767px) {
          font-size: 32px;
          margin-bottom: 16px;
        }
      }
      button {
        font-size: 26px;
        font-family: "benton-sans-wide", sans-serif;
        background-color: #FB6317;
        padding: 6px 15px;
        border: 0;
        @media (max-width: 1599px) {
          font-size: 20px;
        }
        @media (max-width: 1199px) {
          font-size: 18px;
        }
        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
    }
    & > div {
      width: 50%;
      position: relative;
      @media (max-width: 575px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.earnPoints {
  padding: 0 0 132px 0;
  @media (max-width: 1599px) {
    padding: 0 0 90px 0;
  }
  @media (max-width: 1199px) {
    padding: 0 0 60px 0;
  }
  @media (max-width: 767px) {
    padding: 0 0 40px 0;
  }
  .content {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     text-align: center;
     .card {
        width: 380px;
        height: 380px;
        background-color: #f4f4f4;
        border-radius: 10px;
        margin-bottom: 35px;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin-right: 30px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        img {
          width: 120px;
          height: 120px;
          margin-bottom: 10px;
          position: relative;
        }
        h3 {
          font-size: 18px;
          font-family: "benton-sans-wide", sans-serif;
          margin-top: 0;
          font-weight: 700;
          padding: 20px 0px 20px 0px;
        }
        p {
          font-size: 15px;
        }
        button {
          background-color: white;
          color: black;
          font-weight: bold;
          margin-top: 20px;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          border: 1px solid black;
        }
     }
     .card:hover {
      transform: scale(1.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    }
     p {
        text-align: center;
          font-size: 18px;
          margin-bottom: 10px;
        }
     .viewButton {
        font-family: "benton-sans-wide", sans-serif;
        display: "flex";
        justify-content: "center";
        background-color: #FB6317;
        color: #fff;
        font-weight: 700;
        padding: 15px 30px;
        border-radius: 5px;
        cursor: pointer;
        }

  h2.earnPointsText {
    font-weight: bold;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 80px;
    font-family: "benton-sans-wide", sans-serif;
    @media (max-width: 1599px) {
      font-size: 36px;
    }
    @media (max-width: 1199px) {
      font-size: 30px;
    }
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .earnPointsBg {
    background-image: url("../Images/dorman-rewards-img1.jpg");
    background-size: cover;
    border: 1px solid grey;
    display: flex;
    justify-content: right;
    height: 45vh;
    position: relative;
    align-items: center;
    margin: 0;
    @media (max-width: 575px) {
      background-image: url("../Images/dorman-rewards-mobile-img.png");
    }
    img {
      width: 100%;
    }
    .bgrewardsimage {
      padding: 5% 10%;

      h2 {
        font-family: "benton-sans-wide", sans-serif;
        position: absolute;
        left: 1000px;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        max-width: 600px;
        font-family: "Roboto", sans-serif;
        margin-bottom: 40px;
        
      }
      p {
        max-width: 700px;
        color: white;
        font-size: 1.7em;
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        @media (max-width: 1599px) {
          font-size: 1.5em;
          margin-bottom: 18px;
          text-align: justify;
        }
        @media (max-width: 767px) {
          font-size: 1.3em;
          margin-bottom: 17px;
          text-align: justify;
        }
        @media (max-width: 575px) {
          font-size: 1em;
          margin-bottom: 15px;
          text-align: justify;
        }
      }
      button {
        position: absolute;
        font-size: 20px;
        font-weight: 700;
        font-family: "benton-sans-wide", sans-serif;
        background-color: #FB6317;
        color: #fff;
        padding: 6px 15px;
        border: 0;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}
.yourPoints {
  padding: 0 0 132px 0;
  @media (max-width: 1599px) {
    padding: 0 0 90px 0;
  }
  @media (max-width: 1199px) {
    padding: 0 0 60px 0;
  }
  @media (max-width: 767px) {
    padding: 0 0 30px 0;
  }
  h2.earnPointsText {
    font-weight: bold;
    text-align: center;
    font-size: 40px;
    margin-bottom: 90px;
    font-family: "benton-sans-wide", sans-serif;
    @media (max-width: 1599px) {
      font-size: 36px;
      margin-bottom: 90px;
    }
    @media (max-width: 1199px) {
      font-size: 30px;
      margin-bottom: 60px;
    }
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .earnPointsBoxWrap {
    width: 85%;
    margin: auto;
    ul li {
      transition: transform 0.2s ease-in-out;
    }
    ul li:hover {
      transform: scale(1.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    ul {
      display: flex;
      flex-flow: wrap;
      gap: 40px;
      padding: 0;
      margin: 0;
      list-style: none;
      @media (max-width: 991px) {
        gap: 24px;
      }
      li {
        width: calc(33.1% - 28px);
        position: relative;
        @media (max-width: 991px) {
          width: calc(50% - 12px);
        }
        @media (max-width: 575px) {
          width: 100%;
        }
        img {
          width: 100%;
        }
        .earnPointsContent {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          padding: 60px 60px;
          color: #fff;
          text-align: center;
          @media (max-width: 1599px) {
            padding: 28px 40px;
          }
          @media (max-width: 1199px) {
            font-size: 20px;
            padding: 16px 20px;
          }
          @media (max-width: 767px) {
            font-size: 20px;
            padding: 12px;
          }
          .titleWrap {
            min-height: 80px;
            @media (max-width: 1599px) {
              min-height: 80px;
            }
            @media (max-width: 1199px) {
              min-height: 68px;
            }
            @media (max-width: 767px) {
              min-height: 53px;
            }
            h4 {
              font-size: 30px;
              font-weight: 700;
              font-family: "benton-sans-wide", sans-serif;
              @media (max-width: 1599px) {
                font-size: 25px;
              }
              @media (max-width: 1199px) {
                font-size: 21px;
              }
              @media (max-width: 767px) {
                font-size: 17px;
                margin-bottom: 0;
              }
              @media (max-width: 575px) {
                font-size: 30px;
                margin-bottom: 0;
              }
            }
            h6 {
              font-size: 27px;
              font-family: "benton-sans-wide", sans-serif;
              @media (max-width: 1599px) {
                font-size: 22px;
              }
              @media (max-width: 1199px) {
                font-size: 18px;
              }
              @media (max-width: 767px) {
                font-size: 15px;
              }
              @media (max-width: 575px) {
                font-size: 15px;
                margin-bottom: 0;
              }
            }
          }
          p {
            font-size: 20px;
            font-family: "open-sans", sans-serif;
            @media (max-width: 1599px) {
              font-size: 20px;
            }
            @media (max-width: 1199px) {
              font-size: 18px;
            }
            @media (max-width: 767px) {
              font-size: 16px;
            }
            @media (max-width: 575px) {
              font-size: 20px;
            }
          }
          button {
            font-size: 30px;
            width: 100%;
            font-family: "open-sans", sans-serif;
            border: 0px;
            font-weight: 700;
            @media (max-width: 1599px) {
              font-size: 30px;
            }
            @media (max-width: 1439px) {
              font-size: 26px;
            }
            @media (max-width: 1199px) {
              font-size: 20px;
              padding: 6px 16px;
            }
            @media (max-width: 767px) {
              font-size: 18px;
            }
            @media (max-width: 575px) {
              font-size: 22px;
            }
          }
        }
      }
    }
    .viewAllBtn {
      text-align: center;
      margin-top: 108px;
      @media (max-width: 1199px) {
        margin-top: 60px;
      }
      @media (max-width: 767px) {
        margin-top: 30px;
      }
      button {
        font-family: "benton-sans-wide", sans-serif;
        border: 0px;
        padding: 22px 28px;
        font-weight: 700;
        font-size: 35px;
        color: #fff;
        background: #000;
        @media (max-width: 1599px) {
          font-size: 26px;
        }
        @media (max-width: 1199px) {
          font-size: 20px;
          padding: 10px 18px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
}
.faq {
  background-color:#EDEDED;
}
.faq .faqs-inner {
  background-color: #EDEDED;
  width: 90%;
  margin: auto;
  padding-top: 45px;
  padding-bottom: 55px;
}
.faq .hue_headtital h1 {
  font-family:"Open-Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  color: #000;
}
.faq .hue_headtital p {
  text-align: center;
  font-size: 30px;
  color: #000000;
}
.accordiontonprim {
  border-bottom: 1px solid #0000001c;
}
.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid #6c5d9e;
}
.accordion button {
  position: relative;
  display: block;
  text-align: center;
  width: 95%;
  padding: 1.3em 30px;
  color: #000000;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
  margin: auto;
}
.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #6c5d9e;
}
.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #6c5d9e;
  border: 1px solid #6c5d9e;
}
.accordion button .accordion-title {
  font-family:"Open-Sans", sans-serif;
  margin-bottom: 20px;
  font-size: 26px;
  @media (max-width: 575px) {
        font-size: 18px;
  }
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 14px;
  left: 5px;
  width: 20px;
  height: 2px;
  background: #bdbdbd;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 14px;
  width: 2px;
  height: 20px;
  background: #bdbdbd;
}
.accordion button[aria-expanded="true"] {
  color: #6c5d9e;
}
.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 20em;
  transition: all 500ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  font-family:"Open-Sans", sans-serif;
  transition: opacity 500ms linear, max-height 500ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  color: #000000;
  text-align: center;
  padding: 0px 2%;
  font-size: 20px;
  @media (max-width: 575px) {
    font-size: 15px;
  }
}
.accordiontonprim:last-child {
  border-bottom: none;
}

