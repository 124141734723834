// ************Color Variable***********
$black_color: #000000;
$white_color: #ffffff;
$dark_gray_color: #4d4d4d;
$wooden_color: #866d4b;
$light_wooden_color: #a37d49;
$yellow_color: #e5aa2b;
$light_gray_color: #a0a0a0;
$primary_gray_color: #c3c1c1;
$red_color: #ca1e36;

// ************Font Link***************
@import url('https://use.typekit.net/dae6ptz.css');
// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// ************Global Css***************
body {
  // background-color: $black_color !important;
  // color: $white_color;
  font-family: "Open Sans", sans-serif;
}
.container {
  max-width: 95%;
  @media (max-width: 576px) {
    max-width: 100%;
  }
}
.themeBtn {
  background-color: #FB6317;
  color: $white_color;
  border-color: #FB6317;
  text-decoration: none;
  padding: 10px;
  min-width: 250px;
  display: inline-block;
  border-radius: 0;
  text-align: center;
  font-weight: 700;
  font-family: "benton-sans-wide", sans-serif;
  &:hover {
    color: inherit;
    background-color: #FB6317;
    border-color: #FB6317;
  }
  &:active {
    color: inherit;
    background-color: #FB6317;
    border-color: #FB6317;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
}
.homeWrap {
  background-color: $dark_gray_color;
  padding: 64px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
}
.customLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    // width: 100%;
    height: 100px;
    width: 100px;
  }
}

// **********Import Scss File***********
@import "./Header.scss";
@import "./Login.scss";
@import "./Formbg.scss";
@import "./LandingPage";
