.Formbg {
  // background: url('../Images/FormBG.png') no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  @media (max-width: 992px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 576px) {
    padding: 30px 0 20px 0;
  }
  .signUpTopBox {
    .signUpTopBoxTitle {
      //background-color: #FB6317;
      padding: 20px;
      margin-bottom: 40px;
      @media (max-width: 992px) {
        margin-bottom: 24px;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      h4 {
        color: $black_color;
        font-size: 35px;
        font-weight: 700;
        font-family: "benton-sans-wide", sans-serif;
        margin-bottom: 0;
        letter-spacing: initial;
        transform: initial;
        @media (max-width: 992px) {
          font-size: 35px;
        }
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }
    }
    h3 {
      font-size: 30px;
      font-weight: 700;
      font-family: "benton-sans-wide", sans-serif;
      letter-spacing: initial;
      transform: initial;
      color: $black_color;
      margin-bottom: 40px;
      @media (max-width: 992px) {
        margin-bottom: 24px;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
  h4 {
    color: $yellow_color;
    font-family: "benton-sans-wide", sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
    transform: scale(1, 1.4);
    margin-bottom: 52px;
    text-align: center;
    @media (max-width: 992px) {
      margin-bottom: 24px;
      font-size: 24px;
    }
    @media (max-width: 767px) {
      margin-bottom: 24px;
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  h3 {
    font-family: "benton-sans-wide", sans-serif;
    color: $black_color;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 2.6px;
    transform: scale(1, 1.4);
    margin-bottom: 52px;
    text-align: center;
    @media (max-width: 992px) {
      margin-bottom: 36px;
      font-size: 18px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .logoutText {
    text-align: center;
    color: #b08b47;
  }
  .formBox {
    background: #f7f7f7;
    max-width: 600px;
    margin: auto;
    padding: 28px 40px;
    border: 1px solid $wooden_color;
    border-radius: 4px;
    @media (max-width: 576px) {
      padding: 20px 16px;
    }
    @media (max-width: 375px) {
      padding: 12px 10px;
    }
    .verificationInput {
      width: 62% !important;
      @media (max-width: 576px) {
        width: 60% !important;
      }
      @media (max-width: 375px) {
        width: 55% !important;
      }
      input {
        width: 16.6666667% !important;
        height: auto !important;
        font-size: 16px !important;
        padding: 0.7rem 0.1rem !important;
        @media (max-width: 576px) {
          font-size: 12px !important;
        }
      }
    }
    h2 {
     font-family: "benton-sans-wide", sans-serif;
     font-weight: 700;
     font-size: 30px;
     text-align: center;
     margin-bottom: 30px;
     @media (max-width: 992px) {
        font-size: 26px;
     }
     @media (max-width: 576px) {
        font-size: 20px;
      }
      
    }
    .confirmMessage {
      color: $light_wooden_color;
      font-size: 14px;
      margin-top: 20px;
    }
    .signup_label {
        color: #FFFFFF;
        text-align: left;
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 14px;
    }
    p {
      font-size: 18px;
      text-align: center;
      @media (max-width: 576px) {
        font-size: 14px;
      }
      a {
        color: $black_color;
        text-decoration: none;
      }
      &.haveAccount{
        color: $black_color;
      }
    }
    .formWrap {
      text-align: center;
      .react-datepicker-wrapper {
        width: 62%;
        @media (max-width: 576px) {
          width: 60%;
        }
        @media (max-width: 375px) {
          width: 55%;
        }
        .form-control {
          width: 100%;
        }
      }
      .agreeterms {
        font-weight: bold;
        font-size: 15px;
        text-align: left;
        padding-left: 20px;
        margin-bottom: 0px;
      }
      .formControl {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        flex-flow: wrap;
        label {
          width: 38%;
          height: 100%;
          background-color: #464646;
          padding: 0.7rem 0.75rem;
          color: $light_gray_color;
          border: 1px solid #464646;
          text-align: left;
          @media (max-width: 576px) {
            font-size: 12px;
            padding: 0.7rem 0.3rem;
            width: 40%;
          }
          @media (max-width: 375px) {
            width: 45%;
          }
        }
        input {
          width: 62%;
          background-color: #bababa;
          border: 1px solid #bababa;
          padding: 0.7rem 0.75rem;
          border-radius: 0;
          color: $white_color;
          @media (max-width: 576px) {
            font-size: 12px;
            width: 60%;
          }
          @media (max-width: 375px) {
            width: 55%;
          }
          &:focus {
            box-shadow: none;
          }
          &::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        }
      }
      .formControl.error {
        label {
          border: 1px solid $red_color;
          border-right: 0;
        }
        input {
          border: 1px solid $red_color;
        }
      }
      .termsBox, .legalAgeBox {
        //background-color: rgba(40, 40, 40, 0.5);
        padding: 0.7rem 0.75rem;
        color: $black_color;
        //border: 1px solid rgba(40, 40, 40, 0.5);
        text-align: left;
      }
      .signUpBtn {
        margin: 24px 0;
        &:disabled {
          opacity: 0.4;
          background-color: rgb(211, 166, 91);
          border-color: white;
          color: white;
        }
        @media (max-width: 576px) {
          margin: 16px 0;
        }
      }
      .errorMessage {
        color: $red_color;
        margin-bottom: 0;
        text-align: left;
        margin-top: 20px;
      }
    }
  }
}

// ***********************custom checkbox************************

.customCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $white_color;
  a {
    color: #5872ff;
    text-decoration: none;
  }
}

/* Hide the browser's default checkbox */
.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.customCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid $white_color;
  border-radius: 4px;
}

/* Create the customCheckmark/indicator (hidden when not checked) */
.customCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the customCheckmark when checked */
.customCheckbox input:checked ~ .customCheckmark:after {
  display: block;
}

/* Style the customCheckmark/indicator */
.customCheckbox .customCheckmark:after {
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid $white_color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
